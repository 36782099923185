import { IncomingMessage } from 'http';

import {
  ApiClient,
  IConfig,
  IResult,
  QueryArg,
  useClient,
} from '@appmonet/jsonapi-react';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';

import { getSessionFromCookies } from '@/utils/session';

export interface PrivateDealAccount {
  id: string;
}

type AllTypes =
  | 'private-deal-user'
  | 'private-deal'
  | 'private-deal-account'
  | 'ad-preview'
  | 'display-plus-tag'
  | 'creative-detail'
  | 'creative-detail-key'
  | 'generated-display-plus-tag';

type AvailableTypes = `${AllTypes}s`;

type KeysOf<T> = T extends T ? keyof T : never;

type Rels = Partial<{
  [key in AvailableTypes | AllTypes]: { type: AvailableTypes };
}>;

type FieldType = 'string' | 'number' | 'boolean' | 'uuid';

type FieldTypeToType<T extends FieldType> = T extends 'string'
  ? string
  : T extends 'number'
  ? number
  : T extends 'boolean'
  ? boolean
  : string;

type Fields = Record<string, FieldType>;

interface SchemaItem<
  T extends AvailableTypes,
  F extends Fields,
  R extends Rels
> {
  type: T;
  fields: F;
  relationships: R;
}

const rel = <T extends AvailableTypes, F extends Fields, R extends Rels>(
  t: SchemaItem<T, F, R>
) => t;

export const schema = {
  'private-deal-users': rel({
    type: 'private-deal-users',
    fields: {
      id: 'number',
      'first-name': 'string',
      'last-name': 'string',
      'auth-id': 'string',
      email: 'string',
    },
    relationships: {
      'private-deal-account': {
        type: 'private-deal-accounts',
      },
    },
  }),
  'private-deal-accounts': rel({
    type: 'private-deal-accounts',
    fields: {
      id: 'string',
    },
    relationships: {
      'ad-previews': {
        type: 'ad-previews',
      },
    },
  }),
  'private-deals': rel({
    type: 'private-deals',
    fields: {
      id: 'number',
      name: 'string',
      'deal-id': 'string',
      'started-at': 'string',
      'ended-at': 'string',
      'integration-type': 'number',
    },
    relationships: {
      'generated-display-plus-tags': {
        type: 'generated-display-plus-tags',
      },
    },
  }),
  'display-plus-tags': rel({
    type: 'display-plus-tags',
    fields: {
      id: 'number',
      template: 'string',
      'created-at': 'string',
      'updated-at': 'string',
      name: 'string',
    },
    relationships: {
      'generated-display-plus-tags': {
        type: 'generated-display-plus-tags',
      },
    },
  }),
  'generated-display-plus-tags': rel({
    type: 'generated-display-plus-tags',
    fields: {
      id: 'string',
    },
    relationships: {
      'display-plus-tag': {
        type: 'display-plus-tags',
      },
      'private-deal': {
        type: 'private-deals',
      },
    },
  }),
  'creative-detail-keys': rel({
    type: 'creative-detail-keys',
    fields: {
      key: 'string',
      'key-type': 'string',
    },
    relationships: {
      'creative-detail': {
        type: 'creative-details',
      },
    },
  }),
  'creative-details': rel({
    type: 'creative-details',
    fields: {
      id: 'string',
    },
    relationships: {
      'creative-detail-keys': {
        type: 'creative-detail-keys',
      },
      'ad-previews': {
        type: 'ad-previews',
      },
    },
  }),
  'ad-previews': rel({
    type: 'ad-previews',
    fields: {
      id: 'uuid',
      adm: 'string',
      width: 'number',
      height: 'number',
      name: 'string',
      url: 'string',
    },
    relationships: {
      'creative-detail': {
        type: 'creative-details',
      },
      'private-deal-account': {
        type: 'private-deal-accounts',
      },
    },
  }),
};

type Schema = typeof schema;

type SubRelationship<T extends AvailableTypes | AllTypes> =
  `${T}.${AvailableTypes}`;

type RelationshipNames<
  T extends AvailableTypes,
  K extends Schema[T] = Schema[T],
  R extends Rels = K['relationships']
> = KeysOf<R>;

type PossibleIncludes<T extends AvailableTypes> =
  | RelationshipNames<T>
  | SubRelationship<T | AllTypes>;

interface QueryOpt<T extends AvailableTypes> {
  include?: PossibleIncludes<T>[];
  page?: {
    limit?: number;
    size?: number;
    number?: number;
  };
  filter?: Record<string, unknown>;
}

export type HandlerReq = IncomingMessage & {
  cookies: NextApiRequestCookies;
  auth: { sessionId: string | null };
};

type FieldShape<
  T extends AvailableTypes,
  F extends Schema[T]['fields'] = Schema[T]['fields']
> = {
  [K in F extends F ? keyof F : never]: F[K] extends FieldType
    ? FieldTypeToType<F[K]> | null
    : never;
};

type RelationshipsShape<T extends AvailableTypes> = {
  [K in RelationshipNames<T>]?: K extends AvailableTypes
    ? SchemaItemShape<K>[]
    : `${K}s` extends AvailableTypes
    ? SchemaItemShape<`${K}s`>
    : never;
};

export type SchemaItemShape<T extends AvailableTypes> = FieldShape<T> &
  RelationshipsShape<T> & { id: string };

export type FetchMany<T extends AvailableTypes> = QueryArg<any> &
  [type: T, opt?: QueryOpt<T>];

export type FetchOne<T extends AvailableTypes> = QueryArg<any> &
  [type: T, queryParams?: string | string[] | undefined, opt?: QueryOpt<T>];

type TypedQueryArg<T extends AvailableTypes> = FetchMany<T> | FetchOne<T>;

type TypedIResultWrapper<X> = {
  data: X;
  meta: unknown;
  error?: Error | string;
};

type TypedIResultSingle<T extends AvailableTypes> = TypedIResultWrapper<
  SchemaItemShape<T>
>;

export type TypedIResultMulti<T extends AvailableTypes> = TypedIResultWrapper<
  SchemaItemShape<T>[]
>;

type TypedIResult<T extends AvailableTypes> =
  | TypedIResultMulti<T>
  | TypedIResultSingle<T>;

export class TypedApiClient {
  constructor(private readonly apiClient: ApiClient) {}

  public getDelegate(): ApiClient {
    return this.apiClient;
  }

  public fetch<
    T extends AvailableTypes,
    Q extends TypedQueryArg<T>,
    R extends TypedIResult<T> = Q extends FetchMany<T>
      ? TypedIResultMulti<T>
      : Q extends FetchOne<T>
      ? TypedIResultSingle<T>
      : never
  >(queryArg: Q, config?: IConfig): Promise<R> {
    return this.apiClient.fetch(queryArg, config) as any;
  }

  public fetchOne<T extends AvailableTypes>(
    queryArg: FetchOne<T>,
    config?: IConfig
  ): Promise<TypedIResultSingle<T>> {
    return this.fetch<T, FetchOne<T>>(queryArg, config);
  }

  public fetchMany<T extends AvailableTypes>(
    queryArg: FetchMany<T>,
    config?: IConfig
  ): Promise<TypedIResultMulti<T>> {
    return this.fetch<T, FetchMany<T>>(queryArg, config);
  }

  public isFetching = this.apiClient.isFetching.bind(this.apiClient);

  public clearCache = this.apiClient.clearCache.bind(this.apiClient);

  public addHeader = this.apiClient.addHeader.bind(this.apiClient);

  public removeHeader = this.apiClient.removeHeader.bind(this.apiClient);

  public delete<T extends AvailableTypes>(
    queryArg: TypedQueryArg<T>,
    config?: IConfig
  ): Promise<IResult> {
    // TODO: figure out what type this returns
    return this.apiClient.delete(queryArg, config);
  }

  public mutate<T extends AvailableTypes>(
    queryArg: TypedQueryArg<T>,
    data: Partial<SchemaItemShape<T>>,
    config?: IConfig
  ): Promise<IResult> {
    // TODO: figure out the type
    return this.apiClient.mutate(queryArg, data, config);
  }
}

export default function createJSONAPIClient(ssrMode = false): TypedApiClient {
  const client = new ApiClient({
    url: `${process.env.NEXT_PUBLIC_BACKEND_API}/api/v1`,
    ssrMode,
    schema,
  });

  return new TypedApiClient(client);
}

export function asJsonType<T extends AvailableTypes>(
  value: unknown
): SchemaItemShape<T> | null {
  if (value == null || typeof value !== 'object') {
    return null;
  }

  return value as any;
}

export function asJsonTypeMany<T extends AvailableTypes>(
  value: unknown
): SchemaItemShape<T>[] {
  if (value == null) {
    return [];
  }

  if (!Array.isArray(value)) {
    throw new Error('Attempt to convert non-array to json type');
  }

  return value as any[];
}

export function useTypedClient(): TypedApiClient {
  return useClient() as any; // this will only work if we pass the typed one to the provider!
}

export function authHeaders(jwt?: string, sessionId?: string | null): IConfig {
  return {
    headers: {
      clerk_jwt: jwt ?? '',
      clerk_session: sessionId ?? '',
    },
  };
}

export function authConfig(
  req: HandlerReq,
  cookie: string | undefined = undefined,
  sessionId: string | null | undefined = undefined
): IConfig {
  return authHeaders(
    cookie || getSessionFromCookies(req.headers.cookie),
    sessionId || req.auth.sessionId
  );
}
