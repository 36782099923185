import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

import { v4 as uuidv4 } from 'uuid';

const NotificationContext = createContext({});

export interface Info {
  domain: string;
  clicks: number;
  impressions: number;
  spend: number;
  ctr: number;
}
type Actions = 'ADD_NOTIFICATION' | 'REMOVE_NOTIFICATION';

interface NotificationAction {
  type: Actions;
  notification: NotificationMessage | Omit<NotificationMessage, 'id'>;
}

type NotificationType = 'SUCCESS' | 'ERROR' | 'WARNING';

export interface NotificationMessage {
  id: string;
  title: string;
  message: string;
  type?: NotificationType;
}

const reducer = (
  state: { notifications: Array<NotificationMessage> },
  action: NotificationAction
) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return {
        notifications: [
          ...state.notifications,
          { id: uuidv4(), ...action.notification },
        ],
      };
    case 'REMOVE_NOTIFICATION': {
      const filteredNotifications = state.notifications.filter(
        (notification: NotificationMessage) =>
          notification.id !== (action.notification as NotificationMessage).id
      );
      return { notifications: filteredNotifications };
    }
    default:
      return state;
  }
};

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { notifications: [] });
  const value = {
    notifications: state.notifications,
    addNotification: (notification: Omit<NotificationMessage, 'id'>) => {
      dispatch({ type: 'ADD_NOTIFICATION', notification });
    },
    removeNotification: (notification: NotificationMessage) => {
      dispatch({ type: 'REMOVE_NOTIFICATION', notification });
    },
  };
  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () =>
  useContext(NotificationContext) as {
    notifications: Array<NotificationMessage>;
    addNotification: (notification: Omit<NotificationMessage, 'id'>) => void;
    removeNotification: (notification: NotificationMessage) => void;
  };
