import React, { useEffect } from 'react';

import { ApiProvider } from '@appmonet/jsonapi-react';
import { ClerkProvider } from '@clerk/nextjs';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { hotjar } from 'react-hotjar';

import '../styles/global.css';

import createJSONAPIClient from '@/api/jsonApiClient';
import Notification from '@/components/Notification';
import { NotificationProvider } from '@/context/NotificationProvider';

const apiClient = createJSONAPIClient();
const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    hotjar.initialize(3147962, 6);
  }, []);
  const router = useRouter();
  return (
    <>
      <NotificationProvider>
        <Notification />
        <NextNProgress color="#33b8f8" height={6} />
        <ApiProvider client={apiClient as any}>
          <ClerkProvider {...pageProps}>
            <Component {...pageProps} />
          </ClerkProvider>
        </ApiProvider>
      </NotificationProvider>
      <div className="fixed bottom-5 right-5 hidden print:block">
        <img
          className="object-cover h-[35px] "
          src={`${router.basePath}/assets/images/display_plus_logo.png`}
          alt="AdaptMx"
        />
      </div>
    </>
  );
};

export default MyApp;
